import { ActivationTypes, ActivationTypes as EmploymentStatus } from '@cbo/shared-library/types/activation';
import { useMemo } from 'react';
import { useSites } from '../../contexts/siteContext';
import {
  EmployeeBreakReportCommonRequestSchema,
  GenericPayrollExportGridDataRequest,
  IntervalSalesAndLaborReportCommonRequestSchema,
  EmployeeSalesPerformanceCommonRequest,
  AcaDashboardKPICardsRequest,
  ApproachingACAHourlyThresholdGridDataRequest,
  JobcodeHistoryGridRequest,
  OvertimeThresholdCommonRequest,
  EmployeePayrollDto,
  EmploymentStatusNew,
} from '../types';
import { JobCodeDefinition } from '../services/employeeService';

const useLaborQueryKeys = () => {
  const {
    selectedSite: { enterpriseUnitId },
  } = useSites();

  // Query keys dependant on EUID updates:
  const queryKeys = useMemo(() => {
    const employeeConfigurationKeys = {
      allConfigs: ['employee-configurations', enterpriseUnitId] as const,
      all: ['employee-configurations', enterpriseUnitId] as const,
      listById: (id: string) => [...employeeConfigurationKeys.all, id],
      list: () => [...employeeConfigurationKeys.all],
      listByStatus: (status: EmploymentStatus) => [...employeeConfigurationKeys.all, status],
      configByEmployeeId: (employeeId: string) =>
        [[...employeeConfigurationKeys.allConfigs, 'employeeId', employeeId]] as const,
      batchPayRatesByEmployeeJobcodes: (employeeConfigId: string, jobcodeIds: string[]) => [
        ...employeeConfigurationKeys.all,
        employeeConfigId,
        'job-codes',
        jobcodeIds,
      ],
      batchPayRatesAssignedToJobcodeEmployees: (jobcodes: JobCodeDefinition[]) => [
        ...employeeConfigurationKeys.all,
        'job-codes-definitions',
        jobcodes.map((jobCode) => jobCode.id),
      ],
      idByPosEmployeeId: (posEmployeeId: string) =>
        [...employeeConfigurationKeys.allConfigs, 'posEmployeeId', posEmployeeId] as const,
    };

    const jobcodeKeys = {
      all: ['job-code-definitions', enterpriseUnitId] as const,
      list: () => [...jobcodeKeys.all],
      listByStatus: (activationStatus: ActivationTypes) => [...jobcodeKeys.all, activationStatus],
      listById: (id: string) => [...jobcodeKeys.all, id],
      byId: (id: string) => [id],
      jobListByIds: (jobcodes: string[]) => ['jobs', jobcodes.map((jobCode) => jobCode)],
      paginatedList: (page: number, pageSize: number) => [...jobcodeKeys.all, page, pageSize],
    };

    const laborGroupKeys = {
      all: ['labor-groups', enterpriseUnitId] as const,
      listById: (id: string) => [...laborGroupKeys.all, id],
    };

    return {
      employeeConfiguration: employeeConfigurationKeys,
      jobcode: jobcodeKeys,
      laborGroup: laborGroupKeys,
    };
  }, [enterpriseUnitId]);

  // Non EUID-dependent queries:
  const employeeKeys = {
    all: ['employees'] as const,
    byEmpId: (id: string) => [...employeeKeys.all, id],
    listByIdSelf: () => [...employeeKeys.all, 'self' as const],
    listByIds: (empIds: string[]) => ['employees', empIds.sort((a, b) => a.localeCompare(b))],
    listByFilters: (employmentStatus?: EmploymentStatusNew, employeeIds?: string[], posEmployeeId?: string) =>
      ['employees', employmentStatus, employeeIds?.sort((a, b) => a.localeCompare(b)), posEmployeeId] as const,
  };
  // keys for new asm labor service to get employee self details
  const employeeDetailsKeys = {
    all: ['employee-details'] as const,
    self: () => ['self' as const, ...employeeDetailsKeys.all],
  };
  const contactInformationKeys = {
    all: ['contact-information'] as const,
    listByIdSelf: () => [...contactInformationKeys.all, 'self' as const],
    byId: (id: string) => [...contactInformationKeys.all, id],
  };

  const personalInformationKeys = {
    all: ['personal-information'] as const,
    listByIdSelf: () => [...personalInformationKeys.all, 'self' as const],
  };

  const emergencyContactKeys = {
    all: ['emergency-contacts'] as const,
    listByIdSelf: () => [...emergencyContactKeys.all, 'self' as const],
  };

  const certificationKeys = {
    all: ['certifications'] as const,
    listByIdSelf: () => [...certificationKeys.all, 'self' as const],
  };

  const employeeJobCodeAssignmentKeys = {
    all: ['employee-job-code-assignments'] as const,
    listById: (id: string) => [...employeeJobCodeAssignmentKeys.all, id],
  };

  const employeeTipReportKeys = {
    all: ['employee-tip'] as const,
    page: (apiPath: string, request: EmployeeBreakReportCommonRequestSchema) => [
      ...employeeTipReportKeys.all,
      apiPath,
      request.startDate,
      request.endDate,
      request.siteIds?.sort((a, b) => a.localeCompare(b)),
    ],
  };

  const employeeBreakReportKeys = {
    all: ['employee-break'] as const,
    list: (apiPath: string, request: EmployeeBreakReportCommonRequestSchema) => [
      ...employeeBreakReportKeys.all,
      apiPath,
      request.startDate,
      request.endDate,
      request.siteIds?.sort((a, b) => a.localeCompare(b)),
    ],
  };

  const intervalSalesAndLaborKeys = {
    all: ['interval-sales-labor'] as const,
    list: (apiPath: string, request: IntervalSalesAndLaborReportCommonRequestSchema) => [
      ...intervalSalesAndLaborKeys.all,
      apiPath,
      request.startDate,
      request.endDate,
      request.siteIds?.sort((a, b) => a.localeCompare(b)),
      request.jobCodes?.sort((a, b) => a.localeCompare(b)),
      request.laborReportingGroup?.sort((a, b) => a.localeCompare(b)),
    ],
  };

  const employeeSalesPerformanceReportKeys = {
    all: ['employee-sales-performance'] as const,
    list: (request: EmployeeSalesPerformanceCommonRequest, apiPath: string) => [
      ...employeeSalesPerformanceReportKeys.all,
      apiPath,
      request.startDate,
      request.endDate,
      request.siteIds?.sort((a, b) => a.localeCompare(b)),
      request.jobcodes?.sort((a, b) => a.localeCompare(b)),
      request.dayParts,
    ],
  };

  const overtimeThresholdReportKeys = {
    all: ['approaching-overtime-threshold'] as const,
    list: (request: OvertimeThresholdCommonRequest, apiPath: string) => [
      ...overtimeThresholdReportKeys.all,
      apiPath,
      request.startDate,
      request.endDate,
      request.siteIds?.sort((a, b) => a.localeCompare(b)),
      request.hourThresholdLowerRange,
      request.hourThresholdHigherRange,
    ],
  };

  const acaThresholdReportKeys = {
    kpiCards: (request: AcaDashboardKPICardsRequest) => [
      'approaching-aca-threshold',
      'dashboard',
      'kpi-cards',
      request.startDate,
      request.endDate,
      request.siteIds?.sort((a, b) => a.localeCompare(b)),
      request.jobType,
      request.employeeStatus,
    ],
    hourlyGrid: (request: ApproachingACAHourlyThresholdGridDataRequest) => [
      'approaching-aca-threshold',
      'hourly-threshold',
      'grid-data',
      request.startDate,
      request.endDate,
      request.siteIds?.sort((a, b) => a.localeCompare(b)),
      request.employeeStatus,
      request.jobType,
      request.hourThresholdLowerRange,
      request.hourThresholdHigherRange,
    ],
    averageHoursGrid: (request: ApproachingACAHourlyThresholdGridDataRequest) => [
      'approaching-aca-threshold',
      'average-hours',
      'grid-data',
      request.startDate,
      request.endDate,
      request.siteIds?.sort((a, b) => a.localeCompare(b)),
    ],
  };

  const genericPayrollExportKeys = {
    grid: (request: GenericPayrollExportGridDataRequest) => [
      'generic-payroll',
      'grid-data',
      request.startDate,
      request.endDate,
      request.siteIds?.sort((a, b) => a.localeCompare(b)),
    ],
  };

  const jobcodeHistoryKeys = {
    all: ['jobcode-history'] as const,
    list: (request: JobcodeHistoryGridRequest) => [
      ...jobcodeHistoryKeys.all,
      request.employeeId,
      request.siteIds.sort((a, b) => a.localeCompare(b)),
    ],
  };

  const employeePayrollSummaryKeys = {
    all: ['employee-payroll'] as const,
    grid: (request: EmployeePayrollDto, apiPath: string) => [
      ...employeePayrollSummaryKeys.all,
      apiPath,
      'grid-data',
      request.startDate,
      request.endDate,
      request.siteIds?.sort((a: string, b: string) => a.localeCompare(b)),
      request.jobCodeNames?.sort(),
    ],
    list: (request: EmployeePayrollDto, apiPath: string) => [
      ...employeePayrollSummaryKeys.all,
      apiPath,
      request.startDate,
      request.endDate,
      request.siteIds?.sort((a: string, b: string) => a.localeCompare(b)),
      request.jobCodeNames?.sort(),
    ],
  };

  const jobProfileKeys = {
    all: ['job-profiles'] as const,
    listById: (id: string) => [...jobProfileKeys.all, id],
    jobProfilesByEmployeeId: (empid: string) => [jobProfileKeys.all, empid, 'job-profile'],
    jobProfilesByEmployeesIds: (empids: string[]) => [
      jobProfileKeys.all,
      empids?.sort((a, b) => a.localeCompare(b)),
      'job-profile',
    ],
  };

  return {
    ...queryKeys,
    employee: employeeKeys,
    employeeDetails: employeeDetailsKeys,
    contactInformation: contactInformationKeys,
    personalInformation: personalInformationKeys,
    emergencyContact: emergencyContactKeys,
    certification: certificationKeys,
    employeeJobCodeAssignment: employeeJobCodeAssignmentKeys,
    genericPayrollExport: genericPayrollExportKeys,
    overtimeThresholdReport: overtimeThresholdReportKeys,
    employeeTipReport: employeeTipReportKeys,
    employeeBreakReport: employeeBreakReportKeys,
    intervalSalesAndLaborReport: intervalSalesAndLaborKeys,
    employeeSalesPerformanceReport: employeeSalesPerformanceReportKeys,
    acaThresholdReport: acaThresholdReportKeys,
    jobcodeHistory: jobcodeHistoryKeys,
    employeePayrollSummary: employeePayrollSummaryKeys,
    jobProfile: jobProfileKeys,
  };
};

export default useLaborQueryKeys;
